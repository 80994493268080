<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          dense
          :headers="table1.headers"
          :items="table1.items"
          :page.sync="pageTable1"
          :items-per-page="table1.itemsPerPage"
          :loading="table1.loading"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="font-weight-black">Grup Arisan "{{ namaGrup }}"</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-if="grup.deleted_at == null" v-model="table1.dialog" max-width="1024px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialogAdd()">
                    Tambah Anggota
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-simple-table>
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Filter</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-text-field
                                  label="Nama/ kode / bagian/ wilayah"
                                  v-model="table2.search"
                                  outlined
                                  dense
                                  hide-details
                                ></v-text-field>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-btn color="primary" small @click="getTable2()">Lihat</v-btn>
                              </v-toolbar>
                            </template>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">No</th>
                                  <th class="text-left">Anggota</th>
                                  <th class="text-left">Bagian</th>
                                  <th class="text-left">Wilayah</th>
                                  <th class="text-left">Jenis Upah</th>
                                  <th class="text-left">Aksi</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in table2.items" :key="index">
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    {{ item.nama + ' (' + item.kode_anggota + ')' }}
                                  </td>
                                  <td>
                                    {{ item.divisi }}
                                  </td>
                                  <td>
                                    {{ item.wilayah }}
                                  </td>
                                  <td>
                                    {{ item.jenis_upah }}
                                  </td>
                                  <td>
                                    <v-checkbox v-model="table2.selected" label="Pilih" :value="item.id"></v-checkbox>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="5"></td>
                                  <td>
                                    <v-checkbox
                                      class="mt-4"
                                      v-model="table2.selectedAll"
                                      @click="selectAll()"
                                      label="Pilih Semua"
                                    ></v-checkbox>
                                    <v-btn class="mb-2" color="primary" :loading="table2.save_loading" @click="save()"
                                      >Simpan</v-btn
                                    >
                                  </td>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="secondary" @click="close"> Batal </v-btn> -->
                    <!-- <v-btn color="primary" @click="save"> Simpan </v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-if="grup.deleted_at == null" v-model="table4.dialog" max-width="1024px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2 ml-1" v-bind="attrs" v-on="on" @click="openDialogUndian()">
                    Undian
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Daftar Undian Arisan</span>
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      dense
                      :headers="table4.headers"
                      :items="table4.items"
                      :page.sync="pageTable4"
                      :items-per-page="table4.itemsPerPage"
                      :loading="table4.loading"
                      hide-default-footer
                      class="elevation-1"
                    >
                      <template v-slot:top>
                        <v-form class="ml-4">
                          <v-row>
                            <v-col cols="3" md="3">
                              <v-text-field
                                outlined
                                dense
                                v-model="table4.search"
                                label="Nama/Koda anggota *"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="1" md="1">
                              <v-btn class="primary" small @click="optionTable4Change()">Filter</v-btn>
                            </v-col>
                          </v-row>
                        </v-form>
                      </template>
                      <template v-slot:item.anggota="{ item }">
                        {{ `${item.nama_anggota} (${item.kode_anggota})` }}
                      </template>
                      <template v-slot:item.tanggal_kocokan="{ item }">
                        {{ formatDate(item.tanggal_kocokan) }}
                      </template>
                      <template v-slot:item.created_at="{ item }">
                        {{ formatDateTime(item.created_at) }}
                      </template>
                      <template v-slot:item.aksi="{ item }">
                        <v-btn
                          v-if="item.flag_uang_diambil == 0"
                          color="primary"
                          small
                          @click="setAmbilUangUndianArisan(item)"
                          >Ambil</v-btn
                        >
                      </template>
                      <template v-slot:no-data>
                        <v-btn color="primary" small @click="getTable4()"> Reload </v-btn>
                      </template>
                    </v-data-table>
                    <div class="text-center px-4 py-4">
                      <v-row>
                        <v-col cols="3">
                          <v-select
                            label="Jumlah Per Halaman"
                            :items="table4.length_menus"
                            hide-details="auto"
                            outlined
                            dense
                            v-model="table4.itemsPerPage"
                            @change="optionTable4Change()"
                          ></v-select>
                        </v-col>
                        <v-col cols="9">
                          <v-pagination
                            v-model="pageTable4"
                            :length="table4.pageCount"
                            :total-visible="3"
                          ></v-pagination>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-card-actions style="border: 1px solid grey; padding-top: 20px">
                    <v-text-field
                      style="max-width: 200px"
                      type="date"
                      dense
                      hide-details
                      outlined
                      v-model="table4.itemEdit.tanggal"
                      label="Tanggal Undian *"
                    ></v-text-field>
                    <v-divider class="mx-1" inset vertical></v-divider>
                    <v-btn color="primary" @click="getAnggotaUndianArisan()"> Buka Tampilan Undi </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-if="grup.deleted_at == null" v-model="dialogSelesaiGrup" persistent max-width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark class="mb-2 ml-1" color="error" v-bind="attrs" v-on="on"> NON AKTIFKAN </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">Apakah yakin akan me-non aktifkan grup ini?</v-card-title>
                  <v-card-text>Pastikan semua transaksi anggota sudah di selesaikan </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="non_aktifkan()">Ya, Saya Yakin </v-btn>
                    <v-btn color="secondary" @click="dialogSelesaiGrup = false"> Batal </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td><b>Jenis Upah</b> : {{ grup.jenis_upah }}</td>
                  <td><b>Nama</b> : {{ grup.nama }}</td>
                  <td><b>Bunga</b> : {{ grup.bunga }}</td>
                </tr>
                <tr>
                  <td><b>Iuran</b> : {{ formatMoney(grup.iuran) }}</td>
                  <td><b>Jumlah Anggota</b> : {{ grup.total_anggota }}</td>
                  <td>
                    <b>Total Dapat</b> : {{ formatMoney(parseFloat(grup.iuran) * parseFloat(grup.total_anggota)) }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-form class="ml-4">
              <span class="font-weight-black">Keterangan warna:</span>
              <v-alert style="max-width: 200px; display: inline-block" dense class="blue mx-2"> Sudah Dapat</v-alert>
              <v-alert style="max-width: 200px; display: inline-block" dense class="merah mx-2">Belum Lunas</v-alert>
              <v-row>
                <v-col cols="2" md="2">
                  <label class="font-weight-black" for="firstname">Filter Tampilan</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field outlined dense v-model="table1.search" label="Nama/Kode anggota"></v-text-field>
                </v-col>
                <v-col cols="7">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                  <v-btn v-if="grup.deleted_at == null" class="ml-1" color="success" small @click="fixFlagMerah()">
                    <v-icon small title="Perbaiki"> {{ icons.mdiRecycle }} </v-icon> Cek Belum Lunas
                  </v-btn>
                  <v-btn class="ml-1" color="secondary" small @click="cetak()"> Cetak </v-btn>
                  <v-btn class="ml-1" color="secondary" small @click="export_excel()"> Excel </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>

          <template v-slot:item.no="{ item }">
            <div :class="getBackground(item)">{{ item.no }}</div>
          </template>
          <template v-slot:item.nama="{ item }">
            <div :class="getBackground(item)">{{ `${item.nama_anggota} (${item.kode_anggota})` }}</div>
          </template>
          <template v-slot:item.divisi="{ item }">
            <div :class="getBackground(item)">{{ item.divisi }}</div>
          </template>
          <template v-slot:item.wilayah="{ item }">
            <div :class="getBackground(item)">{{ item.wilayah }}</div>
          </template>
          <template v-slot:item.jenis_upah="{ item }">
            <div :class="getBackground(item)">{{ item.jenis_upah }}</div>
          </template>
          <template v-slot:item.x_iuran="{ item }">
            <div :class="getBackground(item)">{{ item.x_iuran }}</div>
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-btn color="primary" small @click="openDialogIuran(item)">
              <v-icon small title="Iuran"> {{ icons.mdiEye }} </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              v-if="item.flag_merah == 1"
              color="success"
              small
              @click="openDialogBayarIuranDariTabungan(item)"
            >
              Bayar
            </v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="getTable1()"> Reload </v-btn>
          </template>
        </v-data-table>
        <div class="text-center px-4 py-4">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="table1.length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table1.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="pageTable1" :length="table1.pageCount" :total-visible="3"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-dialog persistent v-model="table3.dialog" max-width="1024px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Daftar Iuran Arisan</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table dense style="width: 100%" v-if="table3.pasienSelected !== null">
              <tbody>
                <tr>
                  <td><b>Kode Anggota</b> : {{ table3.pasienSelected.kode_anggota }}</td>
                  <td><b>Nama</b> : {{ table3.pasienSelected.nama_anggota }}</td>
                  <td>
                    <b>Wilayah</b> :
                    <span v-if="table3.pasienSelected.wilayah_luar === '1'" style="color: red">{{
                      table3.pasienSelected.wilayah
                    }}</span>
                    <span v-else>{{ table3.pasienSelected.wilayah }}</span>
                  </td>
                </tr>
                <tr>
                  <td><b>Divisi</b> : {{ table3.pasienSelected.divisi }}</td>
                  <td><b>Jenis Upah</b> : {{ table3.pasienSelected.jenis_upah }}</td>
                  <td><b>Jabatan</b> : {{ table3.pasienSelected.jabatan }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-data-table
              dense
              :headers="table3.headers"
              :items="table3.items"
              :page.sync="pageTable3"
              :items-per-page="table3.itemsPerPage"
              :loading="table3.loading"
              hide-default-footer
              class="elevation-1"
            >
              <template v-if="grup.deleted_at == null" v-slot:top>
                <v-toolbar flat>
                  <v-dialog v-model="table3.dialogAddUp" max-width="900px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialogIuranAdd()">
                        Tambah
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Tambah/Edit Iuran Arisan</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col v-if="table3.editedIndex === -1" cols="12" sm="6" md="5">
                              <v-row>
                                <v-col cols="6">
                                  <v-text-field
                                    outlined
                                    readonly
                                    v-model="table3.itemEdit.nominal"
                                    label="Nominal *"
                                    hide-details
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" style="font-weight: bold; padding-top: 30px; color: red">
                                  {{ formatMoney(table3.itemEdit.nominal) }}
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                type="date"
                                outlined
                                v-model="table3.itemEdit.tanggal"
                                label="Tanggal *"
                                hide-details
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" :loading="table3.save_loading" @click="saveIuran()"> Simpan </v-btn>
                        <v-btn color="secondary" @click="table3.dialogAddUp = false"> Batal </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.nominal="{ item }">
                {{ formatMoney(item.nominal) }}
              </template>
              <template v-slot:item.tanggal="{ item }">
                {{ formatDate(item.tanggal) }}
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:item.aksi="{ item }">
                <v-btn color="primary" small @click="openDialogIuranEdit(item)"> Edit Tanggal </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" small @click="getTable3()"> Reload </v-btn>
              </template>
            </v-data-table>
            <div class="text-center px-4 py-4">
              <v-row>
                <v-col cols="3">
                  <v-select
                    label="Jumlah Per Halaman"
                    :items="table3.length_menus"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table3.itemsPerPage"
                    @change="optionTable3Change()"
                  ></v-select>
                </v-col>
                <v-col cols="9">
                  <v-pagination v-model="pageTable3" :length="table3.pageCount" :total-visible="3"></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="closeDialogIuran()"> Keluar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog fullscreen v-model="luckyWheelVar.dialog">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Undian Arian</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="selesaiUndianArisan()"> Simpan </v-btn>
            <v-btn color="secondary" class="ml-2" @click="luckyWheelVar.dialog = false"> Batal </v-btn>
          </v-toolbar>
          <v-card-text>
            <h1 class="my-4">
              Arisan Tanggal "{{ formatDate(table4.itemEdit.tanggal) }}" <br />Yang mendapat adalah:
              <span v-if="Object.keys(luckyWheelVar.anggotaTerpilih).length !== 0"
                >"{{ luckyWheelVar.anggotaTerpilih.nama }} ({{ luckyWheelVar.anggotaTerpilih.kode_anggota }})"</span
              >
            </h1>
            <LuckyWheel
              ref="myLucky"
              width="1024px"
              height="1024px"
              :prizes="luckyWheelVar.prizes"
              :blocks="luckyWheelVar.blocks"
              :buttons="luckyWheelVar.buttons"
              @start="startCallback"
              @end="endCallback"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="table3.dialogBayarDariTabungan" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Bayar Arisan Dari Tabungan</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table dense style="width: 100%" v-if="table3.pasienSelected !== null">
              <tbody>
                <tr>
                  <td><b>Kode Anggota</b> : {{ table3.pasienSelected.kode_anggota }}</td>
                  <td><b>Nama</b> : {{ table3.pasienSelected.nama_anggota }}</td>
                  <td>
                    <b>Wilayah</b> :
                    <span v-if="table3.pasienSelected.wilayah_luar === '1'" style="color: red">{{
                      table3.pasienSelected.wilayah
                    }}</span>
                    <span v-else>{{ table3.pasienSelected.wilayah }}</span>
                  </td>
                </tr>
                <tr>
                  <td><b>Divisi</b> : {{ table3.pasienSelected.divisi }}</td>
                  <td><b>Jenis Upah</b> : {{ table3.pasienSelected.jenis_upah }}</td>
                  <td><b>Jabatan</b> : {{ table3.pasienSelected.jabatan }}</td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-simple-table dense style="width: 100%" v-if="table3.pasienSelected !== null">
              <tbody>
                <tr>
                  <td style="font-size: large">
                    <b>Besar Tabungan: {{ formatMoney(table3.bayarKurangDariTabungan.tabungan) }}</b>
                  </td>
                  <td style="font-size: large">
                    <b>Kurang Bayar: {{ formatMoney(table3.bayarKurangDariTabungan.kurang) }}</b>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="bayarIuranDariTabungan()"> Bayar </v-btn>
            <v-btn color="secondary" @click="table3.dialogBayarDariTabungan = false"> Batal </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<style scoped>
.merah {
  background-color: red;
  color: white !important;
  padding: 5px 8px;
}
.blue {
  background-color: blue !important;
  color: white !important;
  padding: 5px 8px;
}
</style>

<script>
import axios from 'axios'
import { mdiPencil, mdiEye, mdiRecycle } from '@mdi/js'
import { reactive } from '@vue/composition-api'
import { LuckyWheel } from '@lucky-canvas/vue'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  components: {
    LuckyWheel,
  },
  setup() {
    const table1 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Nama', value: 'nama', sortable: false },
        { text: 'Divisi', value: 'divisi', sortable: false },
        { text: 'Wilayah', value: 'wilayah', sortable: false },
        { text: 'Jenis Upah', value: 'jenis_upah', sortable: false },
        { text: 'X Iuran', value: 'x_iuran', sortable: false, align: 'center' },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      itemsPerPage: 100,
      search: '',
      length_menus: [100, 200, 300],
    })
    const table2 = reactive({
      items: [],
      save_loading: false,
      search: '',
      selected: [],
      selectedAll: false,
    })
    const table3 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Nominal', value: 'nominal', sortable: false },
        { text: 'Tanggal', value: 'tanggal', sortable: false },
        { text: 'Created_at', value: 'created_at', sortable: false },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      search: '',
      totalData: 0,
      itemsPerPage: 10,
      length_menus: [10, 25, 50],
      dialog: false,
      dialogAddUp: false,
      dialogBayarDariTabungan: false,
      pasienSelected: null,
      editedIndex: -1,
      itemEdit: {
        id: '',
        id_grup: '',
        id_anggota: '',
        nominal: '',
        tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      },
      itemDefault: {
        id_grup: '',
        id_anggota: '',
        nominal: '',
        tanggal: '',
      },
      bayarKurangDariTabungan: {
        kurang: 0,
        tabungan: 0,
      },
      save_loading: false,
      totalAdded: 0,
    })
    const table4 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Anggota Dapat', value: 'anggota', sortable: false },
        { text: 'Tanggal Undian', value: 'tanggal_kocokan', sortable: false },
        { text: 'Created_at', value: 'created_at', sortable: false },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      search: '',
      totalData: 0,
      itemsPerPage: 10,
      length_menus: [10, 25, 50],
      dialog: false,
      itemEdit: {
        id_grup: '',
        tanggal: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      },
      save_loading: false,
    })
    const luckyWheelVar = reactive({
      dialog: false,
      prizes: [],
      blocks: [{ padding: '13px', background: '#617df2' }],
      buttons: [
        {
          radius: '15%',
          background: '#8a9bf3',
          pointer: true,
          fonts: [{ text: 'Putar', top: '-10px' }],
        },
      ],
      anggotaTerpilih: {},
    })
    function getTable1() {
      this.table1.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable1)
      form.append('itemsPerPage', this.table1.itemsPerPage)
      form.append('search', this.table1.search)
      form.append('id_grup', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/getGrupAnggotassTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.items = response.data.data
            this.table1.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.table1.loading = false
        })
        .catch(error => {
          alert(error)
          this.table1.loading = false
        })
    }
    function getTable2() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('search', this.table2.search)
      form.append('id_grup', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/getAnggotasCanJoinGrup`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table2.selected = []
            this.table2.items = response.data.data
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    }
    function getTable3() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable3)
      form.append('itemsPerPage', this.table3.itemsPerPage)
      form.append('id_anggota', this.table3.itemEdit.id_anggota)
      form.append('id_grup', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/getIuransTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table3.items = response.data.data
            this.table3.pageCount = response.data.length
            this.table3.totalData = response.data.total
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    }
    function getTable4() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable4)
      form.append('itemsPerPage', this.table4.itemsPerPage)
      form.append('search', this.table4.search)
      form.append('id_grup', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/getUndianArisansTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table4.items = response.data.data
            this.table4.pageCount = response.data.length
            this.table4.totalData = response.data.total
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    }

    return {
      table1,
      table2,
      table3,
      table4,
      luckyWheelVar,
      getTable1,
      getTable2,
      getTable3,
      getTable4,
      icons: {
        mdiEye,
        mdiPencil,
        mdiRecycle,
      },
    }
  },
  data() {
    return {
      id_grup: this.$route.params.id,
      namaGrup: this.$route.params.nama,
      grup: {},
      pageTable1: 1,
      pageTable3: 1,
      pageTable4: 1,
      dialogSelesaiGrup: false,
    }
  },
  computed: {
    formTitle() {
      return this.table1.editedIndex === -1 ? 'Form Tambah' : 'Form Edit'
    },
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    pageTable1: {
      handler() {
        this.getTable1()
      },
      deep: true,
    },
    pageTable3: {
      handler() {
        this.getTable3()
      },
      deep: true,
    },
    pageTable4: {
      handler() {
        this.getTable4()
      },
      deep: true,
    },
  },
  created() {
    this.getGrup()
    this.getTable1()
  },
  methods: {
    openDialogAdd() {
      this.table1.editedIndex = -1
      this.getTable2()
    },
    openDialogIuran(item) {
      this.table3.dialog = true
      this.table3.pasienSelected = Object.assign({}, item)
      this.table3.itemEdit.id_anggota = item.id_anggota
      this.table3.itemEdit.id_grup = item.id_grup
      if (this.grup.jenis_upah === 'HARIAN') {
        this.table3.itemEdit.nominal = this.grup.iuran / this.grup.pembagi_harian
      } else {
        this.table3.itemEdit.nominal = this.grup.iuran
      }
      this.getTable3()
    },
    openDialogIuranAdd() {
      this.table3.editedIndex = -1
    },
    openDialogIuranEdit(itemIuran) {
      this.table3.dialogAddUp = true
      this.table3.editedIndex = this.table3.items.indexOf(itemIuran)
      this.table3.itemEdit.tanggal = itemIuran.tanggal
      this.table3.itemEdit.id = itemIuran.id
    },
    openDialogUndian() {
      this.getTable4()
    },
    openDialogBayarIuranDariTabungan(item) {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_grup', item.id_grup)
      form.append('id_anggota', item.id_anggota)
      axios
        .post(`${apiRoot}/api/Arisan/getInfoTabunganDanKurangBayarArisan`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table3.bayarKurangDariTabungan.kurang = response.data.data.totalKurang
            this.table3.bayarKurangDariTabungan.tabungan = response.data.data.totalTabungan
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
      this.table3.pasienSelected = Object.assign({}, item)
      this.table3.itemEdit.id_anggota = item.id_anggota
      this.table3.itemEdit.id_grup = item.id_grup
      this.table3.dialogBayarDariTabungan = true
    },
    closeDialogIuran() {
      this.table3.dialog = false
      if (this.table3.totalAdded > 0) {
        this.getTable1()
        this.table3.totalAdded = 0
      }
    },
    getGrup() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/getGrup`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.grup = response.data.data
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    save() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_anggotas', this.table2.selected)
      form.append('id_grup', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/addBatchGrupAnggota`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.optionChange()
            this.table1.dialog = false
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    optionChange() {
      this.pageTable1 = 1
      this.getTable1()
    },
    optionTable3Change() {
      this.pageTable3 = 1
      this.getTable3()
    },
    optionTable4Change() {
      this.pageTable4 = 1
      this.getTable4()
    },
    selectAll() {
      this.table2.selected = []
      if (this.table2.selectedAll === true) {
        this.table2.items.forEach(item => {
          this.table2.selected.push(item.id)
        })
      } else {
        this.table2.selected = []
      }
    },
    saveIuran() {
      if (this.table3.editedIndex > -1) {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.table3.itemEdit.id)
        form.append('tanggal', this.table3.itemEdit.tanggal)
        axios
          .post(`${apiRoot}/api/Arisan/updateIuran`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.getTable3()
              this.table3.dialogAddUp = false
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id_anggota', this.table3.itemEdit.id_anggota)
        form.append('id_grup', this.id_grup)
        form.append('nominal', this.table3.itemEdit.nominal)
        form.append('tanggal', this.table3.itemEdit.tanggal)
        axios
          .post(`${apiRoot}/api/Arisan/addIuran`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.table3.totalAdded += 1
              this.getTable3()
              this.table3.dialogAddUp = false
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    getAnggotaUndianArisan() {
      this.luckyWheelVar.anggotaTerpilih = {}
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_grup', this.id_grup)
      form.append('tanggal_undian', this.table4.itemEdit.tanggal)
      // form.append('tanggal_undian', '2023-06-03')
      axios
        .post(`${apiRoot}/api/Arisan/getAnggotaUndianArisan`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            let num = parseFloat(response.data.anggota_tms)
            console.log(num)
            if (parseFloat(response.data.anggota_tms) > 0) {
              let conf = confirm(`Terdapat ${num} anggota yang tidak masuk undian. Apakah akan dilanjukan?`)
              if (!conf) {
                this.$store.dispatch('loading', false)
                this.getTable1()

                return 0
              }
            }
            this.luckyWheelVar.prizes = []
            let i = 1
            let bg = ''
            response.data.data.forEach(element => {
              if (i % 2 === 0) {
                bg = '#b8c5f2'
              } else {
                bg = '#e9e8fe'
              }
              this.luckyWheelVar.prizes.push({
                fonts: [{ text: `${element.kode_anggota}`, top: '10%' }],
                background: bg,
                data: {
                  id: element.id,
                  nama: element.nama,
                  id_anggota: element.id_anggota,
                  kode_anggota: element.kode_anggota,
                },
              })
              i++
            })
            this.luckyWheelVar.dialog = true
            this.getTable1()
          } else {
            this.getTable1()
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    selesaiUndianArisan() {
      if (Object.keys(this.luckyWheelVar.anggotaTerpilih).length !== 0) {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id_anggota', this.luckyWheelVar.anggotaTerpilih.id_anggota)
        form.append('id_grup', this.id_grup)
        form.append('tanggal_undian', this.table4.itemEdit.tanggal)
        axios
          .post(`${apiRoot}/api/Arisan/addAnggotaDapatUndianArisan`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.getTable4()
              this.getTable1()
              this.luckyWheelVar.dialog = false
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        this.luckyWheelVar.dialog = false
      }
    },
    setAmbilUangUndianArisan(item) {
      const conf = confirm('Dengan mengambil undian maka transaksi akan dilakukan, anda yakin?')
      if (conf) {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id_grup', item.id_grup)
        form.append('id_anggota', item.id_anggota_dapat)
        axios
          .post(`${apiRoot}/api/Arisan/saveAmbilUndianArisan`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.optionTable4Change()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    getBackground(item) {
      if (item.flag_merah === '1') return 'merah'
      if (item.flag_dapat === '1') return 'blue'

      return ''
    },
    fixFlagMerah() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_grup', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/fixAnggotaFlagmerah`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getTable1()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    non_aktifkan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_grup', this.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/nonAktifkanGrup`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            alert(response.data.message)
            this.$router.push({ name: 'arisan_grup' })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    startCallback() {
      this.$refs.myLucky.play()
      setTimeout(() => {
        const index = Math.floor(Math.random() * this.luckyWheelVar.prizes.length)
        this.$refs.myLucky.stop(index)
      }, 3000)
    },
    endCallback(prize) {
      this.luckyWheelVar.anggotaTerpilih = prize.data
    },
    bayarIuranDariTabungan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_anggota', this.table3.itemEdit.id_anggota)
      form.append('id_grup', this.table3.itemEdit.id_grup)
      axios
        .post(`${apiRoot}/api/Arisan/bayarIuranDariTabungan`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.optionChange()
            this.table3.dialogBayarDariTabungan = false
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    cetak() {
      window.open(`${apiRoot}/api/Arisan/getAnggotaArisanFlagMerah/${this.token}/cetak/${this.id_grup}`, '_blank')
    },
    export_excel() {
      window.open(`${apiRoot}/api/Arisan/getAnggotaArisanFlagMerah/${this.token}/excel/${this.id_grup}`, '_blank')
    },
  },
}
</script>
